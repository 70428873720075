import React from 'react';
import { Link, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import { Col } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import styled from '@emotion/styled';

import Layout from '../components/layout';
import { main } from '../../config/theme';

const Url = styled(Link)`
  ul li &:hover h2 {
    color: #2d2d30 !important;
  }
  text-decoration: none !important;
`;

const Figure = styled.figure`
  color: hsla(0, 0%, 0%, 0.7);
  font-family: 'Lato', 'serif';
  font-weight: normal;
  font-kerning: normal;
  font-feature-settings: 'kern', 'liga', 'clig', 'calt';
  box-sizing: border-box;
  display: block;
  margin: 0 auto;
  padding: 0.83333em;
`;

const List = styled.ul`
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
`;

const Item = styled.li`
  padding-left: 0;
  margin-bottom: 1.83333em;
`;

const Title = styled.h2`
  margin: 0 0 0.43333em;
  padding: 0;
  -webkit-transition: all 0.25s;
  transition: all 0.25s;
  color: #738332 !important;
  font-weight: bold;
  text-rendering: optimizeLegibility;
  font-size: 1.51572rem;
  position: relative;
  line-height: 1.25;
`;

const SubTitle = styled.small`
  font-family: 'Arial', 'sans-serif';
  font-weight: bold;
  display: block;
  color: #2d2d30;
  font-size: 50%;
`;

const Excerpt = styled.p`
  font-weight: inherit;
  margin: 0;
  padding: 0;
  color: #4c4c4c;
`;

const Pagination = styled(Link)`
  display: inline-block;
  color: #2d2d30;
  padding: 8px 16px;
  transition: background-color 0.3s;
  border: 1px solid #ddd;
  margin: 0 1px;
  :hover {
    text-decoration: none;
    color: #abcf1b;
  }
`;

const Blog = ({ data, pageContext, location }) => {
  const { edges } = data.allMarkdownRemark;
  const isFirst = pageContext.currentPage === 1;
  const isLast = pageContext.currentPage === pageContext.numPages;
  const prevPage =
    pageContext.currentPage - 1 === 1
      ? '/blog'
      : `/blog/${(pageContext.currentPage - 1).toString()}`;
  const nextPage = `/blog/${(pageContext.currentPage + 1).toString()}`;

  return (
    <Layout>
      {/* FIXME: SEO component shows warning */}
      <Helmet title="Repodev - Blog" />
      <Col
        md={10}
        className="list-articles mx-auto"
        style={{ paddingTop: 100 }}
      >
        <main className="main" style={main}>
          <Figure className="post listing figure">
            <List className="list">
              {edges.map((val) => (
                <Item className="item" key={val.node.id}>
                  <Url
                    className="link"
                    to={val.node.fields.slug}
                    state={{ prevUrl: location.pathname }}
                  >
                    <Title className="title">
                      {val.node.frontmatter.title}
                      <SubTitle className="sub meta">
                        written by{' '}
                        {`${val.node.frontmatter.author
                          .charAt(0)
                          .toUpperCase()}${val.node.frontmatter.author.slice(
                          1
                        )}`}
                        {/* | {val.node.frontmatter.date} */}
                      </SubTitle>
                    </Title>
                    <Excerpt className="excerpt">{val.node.excerpt}</Excerpt>
                  </Url>
                </Item>
              ))}
            </List>

            {!isFirst && (
              <Pagination to={prevPage} rel="prev">
                ❮ Previous
              </Pagination>
            )}
            {!isLast && (
              <Pagination to={nextPage} rel="next">
                Next ❯
              </Pagination>
            )}
          </Figure>
        </main>
      </Col>
    </Layout>
  );
};

export default Blog;

Blog.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            frontmatter: PropTypes.shape({
              date: PropTypes.string.isRequired,
              path: PropTypes.string.isRequired,
              title: PropTypes.string.isRequired,
              tags: PropTypes.array,
            }),
            html: PropTypes.string.isRequired,
            excerpt: PropTypes.string,
            fields: PropTypes.shape({ slug: PropTypes.string.isRequired }),
          }),
        })
      ),
    }),
  }),
  pageContext: PropTypes.shape({
    currentPage: PropTypes.number.isRequired,
    numPages: PropTypes.number.isRequired,
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }),
};

export const query = graphql`
  query ($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      filter: {
        frontmatter: { published: { eq: true }, type: { eq: "article" } }
      }
      sort: { order: DESC, fields: [frontmatter___date] }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          frontmatter {
            date(formatString: "DD MMMM, YYYY")
            path
            author
            title
            tags
          }
          html
          excerpt(pruneLength: 250)
          fields {
            slug
          }
        }
      }
    }
  }
`;
